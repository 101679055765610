const Button = {
  baseStyle: {
    fontSize: 'sm',
    lineHeight: 'base',
    fontWeight: 'semibold',
    borderRadius: 'lg',
    transition: 'ease 0.5s',
    _disabled: {
      background: 'dark.300',
    },
    _hover: {
      transition: 'ease 0.5s',
      _disabled: {
        background: 'dark.300',
      },
    },
    _focusVisible: {
      outline: 'unset',
      boxShadow: '0px 0px 0px 3px rgba(54, 149, 178, 0.1)',
      borderColor: 'transparent',
    },
  },
  sizes: {
    sm: {
      fontSize: 'xs',
      padding: 3,
    },
    md: {
      fontSize: 'sm',
    },
  },
  variants: {
    primary: {
      bg: 'primary.500',
      color: '#fff',
      _hover: {
        bg: 'primary.600',
      },
    },
    outline: {
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'dark.400',
      color: 'dark.500',
      _hover: {
        color: 'dark.600',
        borderColor: 'dark.600',
      },
      _disabled: {
        background: 'dark.300',
      },
    },
    link: {
      textDecoration: 'underline',

      _hover: {
        color: 'primary.600',
      },
      _disabled: {
        bg: 'transparent',
        color: 'dark.300',
        _hover: {
          bg: 'transparent !important',
          color: 'dark.300',
        },
      },
    },
    unstyled: {
      display: 'flex',
      _active: {
        transform: 'translateY(4px)',
      },
    },
    secondary: {
      display: 'flex',
      border: '1px solid',
      borderColor: 'dark.200',
      background: '#fff',
      _hover: {
        boxShadow: '0px 0px 0px 3px rgba(54, 149, 178, 0.1)',
      },
    },
    close: {
      bg: 'dark.200',
      padding: '6px',
      pos: 'relative',
      minWidth: '28px',
      w: '28px',
      h: '28px',
      _hover: {
        background: 'dark.300',
      },
    },
    danger: {
      background: 'danger.500',
      color: '#fff',
      _hover: {
        background: 'danger.600',
      },
      _active: {
        background: 'danger.500',
      },
      _disabled: {
        background: 'rgba(227, 88, 88, 0.3)',
      },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    fontSize: 'sm',
  },
}

export default Button
