import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      // onSettled: (_, error: any) => {
      //   if (error?.message && error?.code !== 404) {
      //     toaster.error({ description: error.message })
      //   }
      // },
    },
  },
})
