import { Button, Flex, Text } from '@chakra-ui/react'
import React, { ErrorInfo, ReactNode, Component } from 'react'

interface Props {
  children?: ReactNode
}

interface IState {
  /** Flag to indicate if error captured or not */
  hasError: boolean
}

class ErrorBoundary extends Component<Props, IState> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
    }

    this.refreshPage = this.refreshPage.bind(this)
  }

  // Learn more why used at https://reactjs.org/docs/error-boundaries.html
  static getDerivedStateFromError(): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (process.env.NODE_ENV === 'development') {
      console.log({ error: error, errorInfo: info })
    }
  }

  refreshPage() {
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex
          direction={'column'}
          gap='2em'
          alignItems={'center'}
          justifyContent='center'
          h='100vh'
        >
          <Text>Oops, looks like there is some problem we are facing. Please check in later.</Text>
          <Button onClick={this.refreshPage}>Refresh Page</Button>
        </Flex>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
