const colors = {
  primary: {
    100: '#ECF1F6',
    200: '#F5F9FF',
    300: '#F7FDFF',
    400: '#EAF9FE',
    500: '#3695B2',
    600: '#2B758C',
    700: '#2D6C9E',
  },
  success: {
    200: '#DEFCF3',
    400: '#50B295',
    500: '#58C6A5',
    600: '#3F9B7F',
    700: '#255949',
  },
  danger: {
    100: '#FFDEDE',
    200: '#E358581A',
    500: '#E35858',
    600: '#D25050',
    700: '#890000',
  },
  dark: {
    100: '#FAFBFB',
    200: '#F2F3F3',
    300: '#D5D7D7',
    400: '#8A9090',
    500: '#676D79',
    600: '#1A1E25',
  },
  light: {
    100: '#FFFFFF',
    200: '#EAF9FE',
  },
  warning: {
    500: '#FFD362',
  },
  statuses: {
    light: {
      200: '#FEEFDA',
      300: '#DDF1F8',
      400: '#EBE8FF',
    },
    dark: {
      200: '#785320',
      300: '#2B758C',
      400: '#472966',
    },
  },
}

export default colors
