const fontSizes = {
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.3125rem', // 21px
  '2xl': '1.5rem', // 24px
  '3xl': '1.75rem', // 28px
  '4xl': '2rem', // 32px
  '5xl': '2.5rem', // 40px
  '6xl': '3rem', // 48px
  '7xl': '3.25rem', // 52px
  '8xl': '4rem', // 64px
  '9xl': '4.5rem', // 72px
  '10xl': '6rem', // 96px
}

export default fontSizes
