import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Text, Flex, Button } from '@chakra-ui/react'

const Page404 = () => {
  const navigate = useNavigate()
  return (
    <Flex
      w='100%'
      h='100vh'
      direction={'column'}
      alignItems='center'
      justifyContent={'center'}
      gap='2em'
    >
      <Text fontSize={'2em'}>Page Not Found!!</Text>
      <Text>Unfortunately the page you`re looking for doesn`t exist.</Text>
      <Button onClick={() => navigate(-1)}>Go to Previous Page</Button>
    </Flex>
  )
}

export default Page404
