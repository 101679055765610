import React from 'react'
import './FallBackLoader.scss'

const FallBackLoader = () => {
  return (
    <div className='fallbackContainer'>
      <div className='ldsRing'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default FallBackLoader
