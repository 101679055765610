import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { FallBackLoader } from 'components/Loader'
import Home from 'pages/Home'
import Page404 from 'pages/Page404'
import TermsOfService from 'pages/TermsOfService'

const AppRouter = () => {
  return (
    <Suspense fallback={<FallBackLoader />}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/terms' element={<TermsOfService />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default AppRouter
