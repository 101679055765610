import React from 'react'

import theme from 'theme'
import ErrorBoundary from 'components/ErrorBoundary'

import './app.scss'

import AppRouter from 'router/AppRouter'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'utils/queryClient'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <AppRouter />
          </ErrorBoundary>
        </QueryClientProvider>
        <ToastContainer position='bottom-right' autoClose={3000} closeOnClick icon={false} />
      </ChakraProvider>
    </>
  )
}

export default App
