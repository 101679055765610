// theme.js
import { extendTheme, ThemeOverride } from '@chakra-ui/react'

import Button from './component/Buttons'
import Heading from './component/Heading'
import Skeleton from './component/Skeleton'
import Text from './component/Text'
import Tooltip from './component/Tooltip'
import breakpoints from './foundations/breakpoints'
import colors from './foundations/colors'
import fonts from './foundations/fonts'
import fontSizes from './foundations/fontSizes'
import textStyles from './foundations/textStyles'
import styles from './styles'

const overrides: ThemeOverride = {
  config: {
    cssVarPrefix: 'gwcl',
  },
  fonts,
  textStyles,
  breakpoints,
  styles,
  colors,
  fontSizes,
  semanticTokens: {
    colors: {
      primary: 'primary.500',
      error: 'danger.500',
      success: 'success.500',
    },
  },
  components: {
    Button,
    Heading,
    Skeleton,
    Tooltip,
    Text,
  },
}

export default extendTheme(overrides)
