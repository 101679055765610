// configure typography here
const textStyles = {
  h1: {
    fontSize: ['6xl', '6xl', '7xl'],
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  h2: { fontSize: ['3xl', '3xl', '4xl'], fontWeight: 'semibold', lineHeight: 1.2 },
  h3: { fontSize: ['xl', '2xl', '2xl'], fontWeight: 'semibold', lineHeight: 1.2 },
  h4: { fontSize: 'xl', fontWeight: 'semibold' },
  h5: { fontSize: 'lg' },
  h6: { fontSize: 'lg' },
}

export default textStyles
