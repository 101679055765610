import Button from './Buttons'

const Text = {
  baseStyle: {
    fontSize: 'sm',
    lineHeight: 'base',
    fontWeight: 'semibold',
    borderRadius: 'lg',
  },
  sizes: {
    xs: {
      fontSize: 'xs',
      fontWeight: 'medium',
    },
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
    lg: {
      fontSize: 'lg',
    },
    xl: {
      fontSize: 'xl',
      fontWeight: 'bold',
    },
  },
  variants: {
    underlined: {
      color: 'primary.500',
      textDecoration: 'underline',

      _hover: {
        color: 'primary.600',
      },

      _focusVisible: {
        color: 'primary.600',
      },
    },
    outlinedButton: {
      ...Button.baseStyle,
      ...Button.variants.outline,
      ...Button.sizes.md,
      padding: '9.5px 16px',
    },
    subtitle: {
      color: 'dark.500',
      textAlign: 'center',
      fontWeight: 'medium',
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    fontSize: 'sm',
  },
}

export default Text
