const Heading = {
  sizes: {
    '4xl': {
      fontSize: ['6xl', '6xl', '7xl'],
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    '3xl': { fontSize: ['3xl', '3xl', '4xl'], fontWeight: 'semibold', lineHeight: 1.2 },
    '2xl': { fontSize: ['xl', '2xl', '2xl'], fontWeight: 'semibold', lineHeight: 1.2 },
    xl: { fontSize: 'xl', fontWeight: 'semibold' },
    lg: { fontSize: 'lg' },
    md: { fontSize: 'lg' },
    sm: { fontSize: 'lg' },
    xs: { fontSize: 'lg' },
  },
}

export default Heading
