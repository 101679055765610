import { GlobalStyleProps } from '@chakra-ui/theme-tools'

const styles = {
  global: (props: GlobalStyleProps) => ({
    body: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: 'normal',
      fontSize: 'sm',
      color: props.colorMode === 'dark' ? 'white' : 'dark.600',
      lineHeight: 'base',
    },
    '*': {
      '&::selection': {
        background: 'rgba(114, 180, 201, 0.6)',
      },
    },
    input: {
      '&[aria-invalid=true]': {
        border: '1px solid danger.500 !important',
        boxShadow: 'unset !important',
        outline: 'none',
        color: 'inherit',
      },
    },
    a: {
      _focusVisible: { outline: 'unset' },
    },
    '.gm-style-iw-tc': { display: 'none' },
    '.gm-ui-hover-effect': { display: 'none !important' },
  }),
}

export default styles
